import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import Component from '../../constants/Component';
import { FetchDataContext } from '../../context/FetchData';
import './products.scss';
import { Paginator } from 'primereact/paginator';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // your primary color
    },
    secondary: {
      main: '#fff', // your new secondary color
    },
  },
});
const Products = () => {
  let { t } = useTranslation()
  let {
    setCurrentPage,
    setRows,
    totalPage,
    currentPage,
    rows, productsId, subCategories, data, setCategoriesId, products, setProductsId, setSubProductsId, setSubCategories } = useContext(FetchDataContext)

  const [value, setValue] = useState(0);

  // Visual Identity
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCategoriesId(newValue)
    setProductsId(newValue)
  };

  const handleChangeSubCategories = (event, newValue) => {
    setValue(newValue);
    setSubProductsId(newValue)
  };

  const onPageChange = (event) => {
    const newPage = event.first;
    setCurrentPage(newPage + 1);
    setRows(event.rows);
    window.scroll(0, 0)

  }; 
  useEffect(() => {
    setProductsId(productsId)
    window.scroll(0, 0) 
    setSubCategories([])
    document.title = t('product_title')

  }, [productsId])


  return (
    <div className='app__products  app__products_page '>
      <div className='    overflow-hidden  mb-5'  >

        <>
          <ThemeProvider theme={theme}>
            <Box sx={{ width: '80%', margin: 'auto', marginTop: '50px', display: 'flex', justifyContent: 'center', padding: '5px', borderRadius: '15px', border: '1px solid #707070', background: '#fff' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                indicatorColor="secondary"
                // centered={true}
                aria-label="scrollable auto tabs example"
              >
                <Tab style={{ textTransform: 'capitalize', justifyContent: 'center' }} value={0} label={t('product_all')} variant={value === 'visual_production' ? 'contained' : 'standard'} sx={{ justifyContent: 'center' }} />
                {
                  data?.categories?.map((item, index) => (
                    <Tab key={index} style={{ textTransform: 'capitalize', justifyContent: 'center' }} value={item.id} label={item.name} variant={value === 'visual_production' ? 'contained' : 'standard'} sx={{ justifyContent: 'center' }} />
                  ))
                }
              </Tabs>
            </Box>
            {
              subCategories?.length >= 1 &&
              <Box sx={{ width: '80%', margin: 'auto', marginTop: '20px', display: 'flex', justifyContent: 'center', padding: '5px', borderRadius: '50px', border: '1px solid #707070', background: '#fff' }}>
                <Tabs
                  value={value}
                  onChange={handleChangeSubCategories}
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  indicatorColor="secondary"
                  // centered={true}
                  aria-label="scrollable auto tabs example"
                >
                  {
                    subCategories?.map((item, index) => (
                      <Tab key={index} style={{ textTransform: 'capitalize', justifyContent: 'center' }} value={item.id} label={item.name} variant={value === 'visual_production' ? 'contained' : 'standard'} sx={{ justifyContent: 'center' }} />
                    ))
                  }
                </Tabs>
              </Box>
            }

            <Box sx={{ p: 2, overflow: 'hidden' }}>
              <Component.Items page='projects' data={products} />
            </Box>

          </ThemeProvider>
        </>
        <div className="">
          <Paginator first={(currentPage - 1) * rows} rows={rows} totalRecords={totalPage * rows} onPageChange={onPageChange} />
        </div>
      </div>
    </div>
  )
}

export default Products
