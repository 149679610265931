import axios from "axios";
import Cookies from "js-cookie";

export const apiheader = {
    headers: { 
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'application/json',
        'Accept-Language': Cookies.get('i18next'),
    }
};


export const GetData = async (url, header) => {
    let { data } = await axios.get(url, header);
    return data;
}

export const PostData = async (url, body, header) => {
    let data = await axios.post(url, body, header);
    return data;
}