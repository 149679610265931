import React, { createContext, useContext, useEffect, useState } from 'react';
import { LocalizationContext } from './LangChange';
import { GetData, apiheader } from "../utils/fetchData";
import Cookies from 'js-cookie';
import HomeServices from '../utils/HomeServices';
import ProductsServices from '../utils/ProductsServices';
import SubCategories from '../utils/SubCategories';
import Settings from '../utils/Settings';
import SubProductsServices from '../utils/SubProductsServices';
export const FetchDataContext = createContext([])

function FetchData({ children }) {

    const [settings, setSettings] = useState({});
    let { isLang } = useContext(LocalizationContext)

    const [data, setData] = useState([]);
    const [loader, setLoading] = useState(false);

    const [categoriesId, setCategoriesId] = useState(0);
    const [subCategories, setSubCategories] = useState([]);
    const fetchSubCategories = async () => {

        SubCategories(Cookies.get('i18next'), categoriesId).then(({ data }) => {
            if (data?.status === 200) {
                console.log(data?.sub_categories);
                setSubCategories(data?.sub_categories);
                setInterval(() => {
                    setLoading(true)
                }, 2000);
            }

        }).catch((error) => {
        });
    }
    useEffect(() => {
        setTimeout(() => {
            HomeServices(Cookies.get('i18next')).then(({ data }) => {
                if (data?.status === 200) {
                    setData(data);
                    console.log(data?.offers);
                }
            }).catch((error) => {
            });

            Settings(Cookies.get('i18next')).then(({ data }) => {
                if (data?.status === 200) {
                    setSettings(data?.data);
                }
            }).catch((error) => {
            });
        }, 200);

    }, [Cookies.get('i18next')]);
    useEffect(() => {
        const timeOut = setTimeout(() => {
            fetchSubCategories()
        }, 200);
        return () => clearTimeout(timeOut);
    }, [categoriesId, isLang]);
    // *********************************************** Products 

    const [products, setProducts] = useState([]);
    const [productsId, setProductsId] = useState(0);

    const [totalPage, settotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rows, setRows] = useState(1);

    const fetchProducts = async () => {
        ProductsServices(Cookies.get('i18next'), productsId, currentPage).then(({ data }) => {
            if (data?.status === 200) {
                setProducts([])
                setProducts(data?.data?.products?.data);
                settotalPage(data?.data?.products?.lastPage);
                setCurrentPage(data?.data?.products?.currentPage);
            }
        }).catch((error) => {
        });
    }
    const [subProductsId, setSubProductsId] = useState(0);
    const fetchSubProducts = async () => {
        SubProductsServices(Cookies.get('i18next'), subProductsId).then(({ data }) => {
            if (data?.status === 200) {
                setProducts([])
                setProducts(data?.data?.products?.data);
                console.log(data?.data?.products?.data);
            }
        }).catch((error) => {
        });
    }
    useEffect(() => {
        const timeOut = setTimeout(() => {
            fetchProducts()
        }, 200);
        return () => clearTimeout(timeOut);
    }, [productsId, isLang, currentPage]);
    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (subProductsId !== 0) {
                fetchSubProducts()
            }
        }, 200);
        return () => clearTimeout(timeOut);
    }, [subProductsId, isLang]);
    return (
        <FetchDataContext.Provider value={{
            settings,
            data,
            categoriesId,
            setCategoriesId,
            subCategories,
            products,
            setProductsId,
            setSubProductsId,
            setSubCategories,
            totalPage,
            currentPage,
            rows,
            productsId,
            setCurrentPage,
            setRows,
            loader
        }}>
            {children}
        </FetchDataContext.Provider>
    )
}

export default FetchData    