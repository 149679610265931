import { Alert, Box, Button } from '@mui/material';
import AOS from 'aos';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { ConfirmPopup } from 'primereact/confirmpopup';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Icons from '../../constants/Icons';
import { FetchDataContext } from '../../context/FetchData';
import { LocalizationContext } from '../../context/LangChange';
import { ContantServices } from '../../utils/ContantServices';
import img from './../../constants/Img';
import './contact.scss';


const Contact = () => {
    let { t } = useTranslation()
    let { isLang } = useContext(LocalizationContext);
    let { settings } = useContext(FetchDataContext)

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(isLang === "en" ? 'you name is required' : 'يرجى إدخال الاسم'),
        email: Yup.string().email(isLang === "ar" ? 'يرجى إدخال عنوان بريد إلكتروني صحيح' : 'Please enter a valid email address').required(isLang === "en" ? 'The email field is required.' : 'يرجى إدخال عنوان بريد إلكتروني'),
        phone: Yup.string().required(isLang === "en" ? 'The phone field is required.' : 'يرجى إدخال رقم الهاتف'),
        reason: Yup.string().required(isLang === "en" ? 'The reason field is required.' : 'يرجى إدخال السبب'),
        message: Yup.string().required(isLang === "en" ? 'The message field is required.' : 'يرجى إدخال الرسالة'),
    });

    const [meassageApi, setMeassageApi] = useState(null)
    useEffect(() => {
        window.scroll(0, 0)
        AOS.init({
            // once: true, 
        });
        AOS.refresh();
        document.title = t('contact_title')

    }, [])
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            reason: '',
            message: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            await ContantServices(isLang, values).then(({ data }) => {
                console.log(data);
                setMeassageApi(data?.message)
                // Reset the form inputs after successful submission
                resetForm();
            })
        }
    });
    const [visible, setVisible] = useState(true);
    const buttonEl = useRef(null);

    const [visible2, setVisible2] = useState(false);
    const buttonEl2 = useRef(null);

    const [visible3, setVisible3] = useState(false);
    const buttonEl3 = useRef(null);
    let handelClickLocation1 = () => {
        setVisible(!visible)
        setVisible2(false)
        setVisible3(false)
    }
    let handelClickLocation2 = () => {
        setVisible(false)
        setVisible2(!visible2)
        setVisible3(false)
    }
    let handelClickLocation3 = () => {
        setVisible(false)
        setVisible2(false)
        setVisible3(!visible3)
    }
    return (
        <div>
            <div className=" pt-4 app__ourClicnts pb-5" /* style={{ position: 'relative', minHeight: '100vh' }} */>

                <Container>
                    <Row className="mt-8 " dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Col xl={7} lg={7} md={12} sm={12} className=' app__contact-content   '>
                            <div className="container-content">
                                {
                                    meassageApi === null ?
                                        <Form className="form" onSubmit={formik.handleSubmit}>

                                            <Row>
                                                <Col xl={6} lg={6} md={6} sm={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className='label-style'> {t('label_name')} </Form.Label>
                                                        <Form.Control
                                                            name="name"
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.name && formik.errors.name}
                                                            className='input-style' type="text"
                                                            placeholder={t('placeholder_name')} />
                                                        {formik.touched.name && formik.errors.name && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.name}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col xl={6} lg={6} md={6} sm={12}>

                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                                        <Form.Label className='label-style'>{t('label_email')}</Form.Label>
                                                        <Form.Control
                                                            name="email"
                                                            value={formik.values.email}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.email && formik.errors.email}
                                                            className='input-style' type="email" placeholder={t('placeholder_email')} />
                                                        {formik.touched.email && formik.errors.email && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.email}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col xl={6} lg={6} md={6} sm={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                                        <Form.Label className='label-style'>{t('label_phone')}</Form.Label>
                                                        <Form.Control
                                                            name="phone"
                                                            value={formik.values.phone}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.phone && formik.errors.phone}
                                                            className='input-style' type="tel" placeholder={t('placeholder_phone')} />
                                                        {formik.touched.phone && formik.errors.phone && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.phone}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col xl={6} lg={6} md={6} sm={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                                        <Form.Label className='label-style'>{t('label_reason')}</Form.Label>
                                                        <Form.Control
                                                            name="reason"
                                                            value={formik.values.reason}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.reason && formik.errors.reason}
                                                            className='input-style' type="text" placeholder={t('placeholder_reason')} />
                                                        {formik.touched.reason && formik.errors.reason && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.reason}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col xl={12} lg={12} md={12} sm={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
                                                        <Form.Label className='label-style'>{t('label_message')}</Form.Label>
                                                        <Form.Control
                                                            name="message"
                                                            value={formik.values.message}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.message && formik.errors.message}
                                                            className='input-style' as="textarea" rows={5}
                                                            placeholder={t('placeholder_message')} />
                                                        {formik.touched.message && formik.errors.message && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.message}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} marginBottom={'50px'} display="flex" justifyContent='center' alignItems={'center'} gap={'15px'}   >
                                                    <Button
                                                        type='submit'
                                                        className='btn__overlay'
                                                        variant="contained"
                                                        color="warning"
                                                        sx={{
                                                            textTransform: 'capitalize',
                                                            backgroundColor: '#1A4DA6',
                                                            color: '#fff',
                                                            '&:hover': {
                                                                backgroundColor: '#000000',
                                                                color: '#ffffff',
                                                            },
                                                            fontSize: `16px !important`
                                                        }}

                                                    >
                                                        {t('btn_submit')}
                                                    </Button>
                                                </Box>

                                            </Row>
                                        </Form> : 
                                        <>

                                            <Alert variant='standard'>{meassageApi}</Alert>
                                            <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} display="flex" gap={'15px'} justifyContent={'center'} >
                                                <Button
                                                    type="submit"
                                                    className='btn__overlay'
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{
                                                        marginBottom: '20px',
                                                        textTransform: 'capitalize',
                                                        backgroundColor: '#1A4DA6',
                                                        color: '#fff',
                                                        '&:hover': {
                                                            backgroundColor: '#000000',
                                                            color: '#ffffff',
                                                        },
                                                        fontSize: `16px !important`
                                                    }}

                                                    onClick={() => setMeassageApi(null)}
                                                >
                                                    {isLang === "en" ? 'Do you want send anthor messange' : 'هل تريد ارسال طلب اخري'}
                                                </Button>
                                            </Box>
                                        </>
                                }
                            </div>
                        </Col>

                        <Col xl={5} lg={5} md={false} className=' d-f lex justify-content-center align-items-center' sm={false}>
                            <Container fluid className='map-contactus    ' >
                                <h5 className='mb-4 text-center'> {t('title_map')}</h5>
                                <img src={img.contactUS} alt="" className='w-100' />
                                <div className={`location1 ${visible ? 'loactionActive' : ''}`} ref={buttonEl} onClick={() => handelClickLocation1()} ></div>
                                <div className={`location2 ${visible2 ? 'loactionActive' : ''}`} ref={buttonEl2} onClick={() => handelClickLocation2()}></div>
                                <div className={`location3 ${visible3 ? 'loactionActive' : ''}`} ref={buttonEl3} onClick={() => handelClickLocation3()}></div>
                                <ConfirmPopup
                                    className='p-confirm-popup-location1 overflow-hidden'
                                    target={buttonEl.current}
                                    visible={visible}
                                    onHide={() => setVisible(false)}
                                    message={
                                        <div className="custom-popup-content overflow-hidden" >
                                            <Row>
                                                <Col className={` flex justify-content-center align-items-start ${Cookies.get('i18next') === "en" ? 'ml-3' : 'mr-3'} flex-column m-0`}>
                                                    <h4>(1)</h4>
                                                    <p> {t('app_title')}  </p>
                                                </Col>
                                                <Col className=' flex justify-content-end p-0 m-0'>
                                                    <img src={img.contactIMG1} className='w-100' alt="وصف الصورة" />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    footer={
                                        <div className="custom-popup-footer">
                                            {/* يمكنك ترك هذا المكان فارغًا لعدم وجود أزرار */}
                                        </div>
                                    }
                                />
                                <ConfirmPopup
                                    className='p-confirm-popup-location1 overflow-hidden'
                                    target={buttonEl2.current}
                                    visible={visible2}
                                    onHide={() => setVisible2(false)}
                                    message={
                                        <div className="custom-popup-content overflow-hidden" >
                                            <Row>
                                                <Col className={` flex justify-content-center align-items-start ${Cookies.get('i18next') === "en" ? 'ml-3' : 'mr-3'} flex-column m-0`}>
                                                    <h4>(2)</h4>
                                                    <p> {t('app_title')}  </p>
                                                </Col>
                                                <Col className=' flex justify-content-end p-0 m-0'>
                                                    <img src={img.contactIMG2} className='w-100' alt="وصف الصورة" />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    footer={
                                        <div className="custom-popup-footer">
                                            {/* يمكنك ترك هذا المكان فارغًا لعدم وجود أزرار */}
                                        </div>
                                    }
                                />

                                <ConfirmPopup
                                    className='p-confirm-popup-location1 overflow-hidden'
                                    target={buttonEl3.current}
                                    visible={visible3}
                                    onHide={() => setVisible3(false)}
                                    message={
                                        <div className="custom-popup-content overflow-hidden" >
                                            <Row>
                                                <Col className={` flex justify-content-center align-items-start ${Cookies.get('i18next') === "en" ? 'ml-3' : 'mr-3'} flex-column m-0`}>
                                                    <h4>(3)</h4>
                                                    <p> {t('app_title')}  </p>
                                                </Col>
                                                <Col className=' flex justify-content-end p-0 m-0'>
                                                    <img src={img.contactIMG3} className='w-100' alt="وصف الصورة" />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    footer={
                                        <div className="custom-popup-footer">
                                            {/* يمكنك ترك هذا المكان فارغًا لعدم وجود أزرار */}
                                        </div>
                                    }
                                />


                            </Container>
                        </Col>
                    </Row>
                    <div className="addres">
                        <div className="icons">
                            <Icons.location />
                        </div>
                        <h3>RM47+X3V, Jasmine, Riyadh, Saudi Arabia</h3>
                    </div>
                    <iframe
                        src={`https://www.google.com/maps?q=${Number(settings?.locations[0]?.lat)},${(Number(settings?.locations[0]?.long))}&hl=es&z=10&output=embed`}
                        width="100%"
                        height={'600px'}
                        style={{ border: '0px' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>

                </Container>
            </div>

        </div >
    )
}

export default Contact