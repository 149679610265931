import React, { useContext, useEffect } from 'react'
import img from '../../constants/Img'
import { Container, Row, Col } from 'react-bootstrap'
import { motion } from 'framer-motion';
import Component from '../../constants/Component';
import './clients.scss'
import { FetchDataContext } from '../../context/FetchData';
import { useTranslation } from 'react-i18next';
const Clients = () => {
  let { data } = useContext(FetchDataContext)
  const { t } = useTranslation()
  
  useEffect(() => {
    window.scroll(0, 0)  
    document.title = t('client_title')

  }, [])
  return (
    <div>
      <div className=" mt-5 app__ourClicnts mb-5" /* style={{ position: 'relative', minHeight: '100vh' }} */>

        <Container>
          <div className="app__clients">
            <div className="app__clients_title flex justify-content-center flex-column align-items-center w-100">
              <div className="header">
                <h3>{data?.clients?.section_title}  </h3>
              </div>
              <p>{data?.clients?.section_content}</p>
            </div>
          </div>

          <Row>
            {
              data?.clients?.clients?.map((item, index) => (
                <Col key={index} xl={4} lg={4} md={4} sm={4} className='d-flex align-item-center justify-content-center mt-5'>
                  <motion.img
                    style={{ cursor: 'pointer', objectFit: 'contain', objectPosition: '50% 50%' }}
                    src={item.image}
                    width={'50%'}
                    alt={'company.title'}
                    whileInView={{
                      scale: [0, 1],
                      transition: {
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        delay: 0.5,
                        transition: 0.5
                      }
                    }}
                  />
                </Col>
              ))
            }

          </Row>
        </Container>

      </div>

    </div>
  )
}

export default Clients