import { useState } from 'react'
import './style/App.scss'
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import Component from './constants/Component';
import FetchData from './context/FetchData';
import LangChange from './context/LangChange';

function App() {

  const root = createBrowserRouter([
    {
      path: `/`, element: <Component.Alwatan />, children: [
        { index: true, element: <Component.Home /> },
        { path: 'home', element: <Component.Home /> },
        { path: 'about', element: <Component.About /> },
        { path: '', element: <Component.Products /> },
        {
          path: 'products', children: [
            { index: true, element: <Component.Products /> },
            { path: 'details/:id', element: <Component.ProductDetails /> }
          ]
        },
        { path: 'services', element: <Component.Services /> },
        { path: 'client', element: <Component.Clients /> },
        { path: 'contact', element: <Component.Contact /> },

        { path: '*', element: <h1>Page Notfound</h1> },
      ],
    },
  ])
  return (
    <>
      <LangChange>
        <FetchData>
          <RouterProvider router={root} />
        </FetchData>
      </LangChange>
    </>
  )
}

export default App
