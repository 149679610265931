import { AnimatePresence, motion } from 'framer-motion';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { Menubar } from 'primereact/menubar';
import React, { useContext, useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icons from '../../constants/Icons';
import img from '../../constants/Img';
import { FetchDataContext } from '../../context/FetchData';
import { LocalizationContext } from '../../context/LangChange';
import './navbar.scss';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
]

const NavBar = ({ navbarColor }) => {
    let { settings } = useContext(FetchDataContext)

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    let { isLang, setIsLang } = useContext(LocalizationContext);
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';

    }, [currentLanguage, t])
    const handleLanguageChange = (newLanguage) => {
        setIsLang(newLanguage);
    };
    const items = [
        {
            label: <Link to={'/'}>{t('nav_home')}</Link>,
        },
        {
            label: <Link to={'/about'}>{t('nav_about')}</Link>,
        },
        {
            label: <Link to={'/products'}>{t('nav_product')}</Link>,
        },
        {
            label: <Link to={'/services'}>{t('nav_service')}</Link>,
        },
        {
            label: <Link to={'/client'}>{t('nav_client')}</Link>
        },
        {
            label: <Link to={'/contact'}>{t('nav_contact')}</Link>,
        },

    ];
    const [scrollPosition, setScrollPosition] = useState(0);

    const start = <Link to={'/'}><img alt="logo" src={img.Logo} height="70" className="mr-2"></img></Link>;
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className='app__navbar '  >
            <AnimatePresence >
                {scrollPosition <= 400 && (
                    <motion.div
                        className="navbar__social "
                        initial={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}>
                        <Navbar className={`px-4 navbar__social-Container `} >


                            <div className="calling">
                                <motion.a
                                     href={`mailto: ${settings?.email}`}
                                    target='_blank'
                                    rel="noreferrer"
                                    whileHover={{ y: -5, scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                    className='flex flex-row gap-2 align-items-center'
                                >
                                    <Icons.Email /> <span className='text-light navbar_icons_sm'>{settings?.email}</span>
                                </motion.a>

                                <motion.a
                                     href={`tel:${settings?.phone}`}
                                    target='_blank' rel="noreferrer"
                                    whileHover={{ y: -5, scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                    className='flex flex-row gap-2 align-items-center'
                                    dir="ltr"

                                >
                                    <Icons.Phone className='navbar-sm-icons-phone' />  <span className='text-light navbar_icons_sm'>{settings?.phone}</span>
                                </motion.a>
                            </div>
                            <div className="social">
                                <div className="social_media">
                                    <motion.a
                                          href={settings?.facebook}
                                        target='_blank' rel="noreferrer"
                                        whileHover={{ scale: 1.2 }}
                                        transition={{ duration: 0.3 }}
                                        className='m-0 p-0'
                                    >
                                        <Icons.Facebook color={'#ffff'} />
                                    </motion.a>
                                    <motion.a
                                        href={settings?.twitter}
                                        target='_blank' rel="noreferrer"
                                        whileHover={{ scale: 1.2 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Icons.Twitter color={'#ffff'} />
                                    </motion.a>
                                    <motion.a
                                         href={settings?.instagram}
                                        target='_blank' rel="noreferrer"
                                        whileHover={{ scale: 1.2 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Icons.Instagram color={'#ffff'} />
                                    </motion.a>
                                    <motion.a
                                         href={settings?.linked_in}
                                        target='_blank' rel="noreferrer"
                                        whileHover={{ scale: 1.2 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Icons.Linkedin color={'#ffff'} />
                                    </motion.a>
                                </div>

                                <div className={`${isLang === "en" ? 'center' : 'center center-rtl'}`}>


                                    {
                                        cookies.get('i18next') === "en" ?
                                            <div className="change-lang  "
                                                onClick={() => {
                                                    handleLanguageChange('ar')
                                                    i18next.changeLanguage('ar')
                                                }}  >
                                                <Icons.Language />
                                                <span className='text ' style={{ marginBottom: '4px' }}>  عربيه</span>
                                            </div> :
                                            <div className="change-lang" onClick={() => {
                                                handleLanguageChange('en')
                                                i18next.changeLanguage('en')
                                            }} >
                                                <Icons.Language />
                                                <span className='text ' /* style={{ marginBottom: '2px' }} */>  English</span>
                                            </div>
                                    }
                                </div>
                            </div>
                        </Navbar>
                    </motion.div>
                )}
            </AnimatePresence >
            <Navbar
                dir='rtl'
                fixed={scrollPosition > 400 ? 'top' : 'top'}
                collapseOnSelect
                expand="sm"
                className={`${navbarColor} ${scrollPosition > 400 ? 'scroll navbar-navOnthorPAge shadow-sm ' : ''}`}
            >
                <Menubar model={items} end={start} />
            </Navbar>
        </div>
    )
}

export default NavBar
