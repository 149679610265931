import React, { useContext, useEffect } from 'react'
import img from '../../constants/Img'
import { Carousel, Container } from 'react-bootstrap'
import cookies from 'js-cookie'
import Component from '../../constants/Component'
import { useTranslation } from 'react-i18next'
import { FetchDataContext } from '../../context/FetchData'
import './about.scss'
import Cookies from 'js-cookie'

const About = () => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scroll(0, 0) 
    document.title = t('about_title')
  }, [t])
  let { data } = useContext(FetchDataContext)

  return (
    <div>
      <div >
        <Carousel className="app__slider-home" interval={3000} fade showArrows={false} prevIcon={null} nextIcon={null}>
          {
            data?.about_us?.sliders?.map((item, index) => (
              <Carousel.Item key={index} className="   w-100 ">
                <img src={item.image} alt="Slider 2" className="slider__home  w-100" />
                <Carousel.Caption className={`${cookies.get('i18next') === "en" ? 'content__silder  ' : 'content__silder content__silder-rtl'}  `}>
                  <div className="content">
                    <span>{item?.title} </span>
                    <p>{item?.description}</p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))
          }

        </Carousel>
      </div>

      <Container>
        <div className="app__about_content">
          <div className="img">
            <img src={img?.AboutImg} alt="" srcset="" />
          </div>
          <div className="content">
            <h1> {t("owner")} </h1>
            <ul>
              <li> {t("position1")}  </li>
              <li> {t("position2")}  </li>
            </ul>
          </div>
        </div>
        <div className="app__about mt-6">
          <div className=" app__about_title2 flex justify-content-center flex-column align-items-start w-">
            <div className="header">
              <h3>{data?.about_us?.section_title} </h3>
            </div>
            <p>{data?.about_us?.section_content} </p>
          </div>
        </div>
        <div className="app__vision  mt-4">
          <div className=" app__about_title2 flex justify-content-center flex-column align-items-start w-">
            <div className="header">
              <h4> {data?.about_us?.our_vision_title}    </h4>
            </div>
            <p>{data?.about_us?.our_vision_description} </p>
          </div>
        </div>
        {
          data?.about_us?.goals?.length > 0 &&
          <div className="app__vision  mt-4">
            <div className=" app__about_title2 flex justify-content-center flex-column align-items-start w-">
              <div className="header">
                <h4> {t("our_goals")}  </h4>
              </div>
              <ul>
                {
                  data?.about_us?.goals?.map((item, index) => (
                    <li key={index}>{Cookies.get("i18next") == "en" ? item?.title_en : item?.title_ar}</li>
                  ))
                }
              </ul>
            </div>
          </div>
        }
        {
          data?.about_us?.values?.length > 0 &&
          <div className="app__value mt-5">
            <div className=" app__about_title2 flex justify-content-center flex-column align-items-start w-">
              <div className="header">
                <h3>{t("our_value")} </h3>
              </div>
              <div className="content">
                {
                  data?.about_us?.values?.map((item, index) => (
                    <div  key={index} className="Credibility mt-6">
                      <div className="header">
                        <img src={img.highQuality} alt="" srcset="" />
                        <span>{item?.title}</span>
                      </div>
                      <p>{item?.description}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        }
      </Container>
      <Component.Quality data={data?.quality} t={t} />
    </div>
  )
}

export default About
