import Logo from "../assets/Images/Logo.png";
import LogoLight from "../assets/Images/LogoLight.png";
import area from "../assets/Images/area.png";
import businessman from "../assets/Images/businessman.png";
import certificate from "../assets/Images/certificate.png";
import manufacture from "../assets/Images/manufacture.png";
import production from "../assets/Images/production.png";
import sales from "../assets/Images/sales.png";
import Quality from "../assets/Images/Our Quality.png";
import ServicesAr from "../assets/Images/services2_Ar.png";
import Services2 from "../assets/Images/services2.png";
import Item1 from "../assets/Images/products/item1.png";
import Item2 from "../assets/Images/products/item2.png";
import Item3 from "../assets/Images/products/item3.png";
import Item4 from "../assets/Images/products/item4.png";
import Item5 from "../assets/Images/products/item5.png";
import Item6 from "../assets/Images/products/item6.png";
import Item7 from "../assets/Images/products/item7.png";
import Item8 from "../assets/Images/products/item8.png";
import Item9 from "../assets/Images/products/item9.png";
import Item10 from "../assets/Images/products/item10.png";
import Offer1 from "../assets/Images/products/offer1.png";
import Offer2 from "../assets/Images/products/offer2.png";
import clients1 from "../assets/Images/clients/clients1.jpg";
import clients2 from "../assets/Images/clients/clients2.png";
import clients3 from "../assets/Images/clients/clients3.png";
import clients4 from "../assets/Images/clients/clients4.png";
import clients5 from "../assets/Images/clients/clients5.png";
import clients6 from "../assets/Images/clients/clients6.png";
import subscribe1 from "../assets/Images/subscribe/subscribe1.png";
import subscribe2 from "../assets/Images/subscribe/subscribe2.png";
import subscribe3 from "../assets/Images/subscribe/subscribe3.png";
import subscribe4 from "../assets/Images/subscribe/subscribe4.png";
import services1 from "../assets/Images/services/services1.png";
import services2 from "../assets/Images/services/services2.png";
import services3 from "../assets/Images/services/services3.png";
import services4 from "../assets/Images/services/services4.png";
import services5 from "../assets/Images/services/services5.png";
import services6 from "../assets/Images/services/services6.png";
import services7 from "../assets/Images/services/services7.png";
import services8 from "../assets/Images/services/services8.png";

import about_slider1 from "../assets/Images/About/about_slider1.png";
import about_slider2 from "../assets/Images/About/about_slider2.png";
import about_slider3 from "../assets/Images/About/about_slider3.png";
import about_dot from "../assets/Images/About/Group 16032.png";

import AboutHome from "../assets/Images/about.png";
import AboutImg from "../assets/Images/About/1673946232160 copy 2.png";
import growth from "../assets/Images/About/growth.png";
import highQuality from "../assets/Images/About/high-quality.png";
import premium from "../assets/Images/About/premium-badge.png";
import shield from "../assets/Images/About/shield.png";
import contactUS from "../assets/Images/contact/Untitled-1.png";
import ProductDetails1 from "../assets/Images/products/WhatsApp Image 2023-03-29 at 1.57.48 PM (1) copy.png";
import ProductDetails2 from "../assets/Images/products/WhatsApp Image 2023-03-29 at 1.57.48 PM copy.png";
import ProductDetails3 from "../assets/Images/products/WhatsApp Image 2023-03-29 at 1.57.49 PM copy.png";
import contactIMG1 from "../assets/Images/clients/IMG_2892.png";
import contactIMG2 from "../assets/Images/clients/IMG_3109.png";
import contactIMG3 from "../assets/Images/clients/IMG_2944.png";

const img = {
    Logo,
    contactIMG1,
    contactIMG2,
    contactIMG3,
    contactUS,
    ProductDetails1,
    ProductDetails2,
    ProductDetails3,
    LogoLight,
    area,
    businessman,
    certificate,
    manufacture,
    production,
    sales,
    about_dot,
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item10,
    Offer1,
    Offer2,
    ServicesAr,
    Services2,
    AboutHome,
    Quality,
    clients1,
    clients2,
    clients3,
    clients4,
    clients5,
    clients6,
    subscribe1,
    subscribe2,
    subscribe3,
    subscribe4,
    services1,
    services2,
    services3,
    services4,
    services5,
    services6,
    services7,
    services8,
    about_slider1,
    about_slider2,
    about_slider3,
    AboutImg,
    growth,
    highQuality,
    premium,
    shield
}

export default img;
