import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Icons from '../../constants/Icons'
import img from '../../constants/Img'
import { Button } from 'primereact/button'

const Items = ({ data }) => {
    return (
        <Container className='app__products'>
            <p className="m-0">
                <Row>
                    {
                        data?.map((item, index) => (
                            <Col key={index} xl={4} lg={4} md={6} sm={12} className='mt-8'>
                                <Link to={`/products/details/${item?.id}`}>
                                    <div className="app__items">
                                        <img src={item?.image} className='w-100' alt="" />
                                        <div className="app__items_bg">
                                            <div className="app__items_bg_footer  ">
                                                <span className='item_name'>( {item.name} )</span>
                                                <Link to={`/products/details/${item?.id}`} className='more_details flex gap-1 align-items-center'>
                                                    <span>More</span>  <Icons.ArrowRight />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))
                    }
             
                </Row>
            </p>
        </Container>
    )
}

export default Items