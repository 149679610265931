import React from 'react';
import { Col, Row } from 'react-bootstrap';
import img from '../../../constants/Img';
import './subscribe.scss';

const SubscribeHome = () => {
 

    return (
        <div className='app__Subscribe'>
            <Row>
                <Col className='app__app__Subscribe_sm '>
                    <div className="overlay1 h-100">
                        <img src={img.subscribe3} alt="" className='w-100 ' />
                        <div className="overlay"></div>
                    </div>
                </Col>
                <Col className='app__app__Subscribe_sm '>
                    <div className="overlay2 h-100">
                        <img src={img.subscribe4} alt="" className='w-100' />
                        <div className="overlay"></div>
                    </div>
                </Col>
                <Col  >
                    <div className="overlay3">
                        <img src={img.subscribe2} alt="" className='w-100' />
                        <div className="overlay"></div>
                    </div>
                </Col>
                <Col  >
                    <div className="overlay4">
                        <img src={img.subscribe1} alt="" className='w-100' />
                        <div className="overlay"></div>
                    </div>
                </Col>
            </Row>
            {/* <div className="mail_subscribe">
                <div className="Touch">
                    Get In Touch
                </div>
                <div className="submit flex align-content-center p-1    justify-content-between w-100 gap-5">
                    <InputText value={value} onChange={(e) => setValue(e.target.value)} placeholder='Enter email' />
                    <Button label="Submit" icon={<Icons.ArrowRight className='svg'/>} text rounded  /> 
                </div>
            </div> */}
        </div>
    )
}

export default SubscribeHome
