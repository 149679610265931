import React, { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FetchDataContext } from '../../context/FetchData'
import './services.scss'

const Services = () => {
  const { t } = useTranslation()

  let { data } = useContext(FetchDataContext)
  useEffect(() => {
    document.title = t('service_title')

    window.scroll(0, 0)
  }, []);
  return (
    <>

      <div className="app__clients_title flex justify-content-center flex-column align-items-center w-100">
        <div className="header">
          <h3>{data?.services?.section_title} </h3>
        </div>
        <p>{data?.services?.section_content}</p>
      </div>

      <div className=" mt-5 app__services_a mb-5" /* style={{ position: 'relative', minHeight: '100vh' }} */>


        <Container>
          <Row >
            {
              data?.services?.services?.map((item, index) => (
                <Col key={index} xl={6} lg={6} md={6} sm={12} className='mt-5'>
                  <img src={item?.image} alt="" className='w-100' />
                  <div className="content">
                    <h3>{item?.title}    </h3>
                  </div>
                </Col>
              ))
            }
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Services