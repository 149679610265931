import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './summary.scss'
import img from '../../../constants/Img'
const Summary = ({ data, t }) => { 
    return (
        <div className='app__summary '>
            <div className="app__summary_title flex justify-content-center flex-column align-items-center w-100">
                <div className="header">
                    <h3>{t('app_title')}</h3>
                </div>
           {/*      <p>in numbers</p> */}
            </div>
            <Row className='mt-6'>
                <Col xl={2} lg={2} md={4} sm={6}    className='mt-4 xs_col'>
                    <div className="box_summary">
                        <div className="title_summary">
                            <span>{t('num_experience')}</span>
                        </div>
                        <div className="img_summary">
                            <img src={img.certificate} alt="app_area" />
                        </div>
                        <div className="footer__summary flex flex-column">
                            <span className='num'> {data?.experience} </span>
                            <span className='text'>{t('text_experience')}  </span>
                        </div>
                    </div>
                </Col>
                <Col xl={2} lg={2} md={4} sm={6}    className='mt-4 xs_col'>
                    <div className="box_summary">
                        <div className="title_summary">
                            <span>{t('num_factory')}</span>
                        </div>
                        <div className="img_summary">
                            <img src={img.production} alt="app_area" />
                        </div>
                        <div className="footer__summary flex flex-column">
                            <span className='num'> {data?.factory} </span>
                            <span className='text'>{t('text_factory')}  </span>
                        </div>
                    </div>
                </Col>
                <Col xl={2} lg={2} md={4} sm={6}    className='mt-4 xs_col'>
                    <div className="box_summary">
                        <div className="title_summary">
                            <span>{t('num_space')}</span>
                        </div>
                        <div className="img_summary">
                            <img src={img.area} alt="app_area" />
                        </div>
                        <div className="footer__summary flex flex-column">
                            <span className='num'> {data?.space} </span>
                            <span className='text'>{t('text_space')}  </span>
                        </div>
                    </div>
                </Col>
                <Col xl={2} lg={2} md={4} sm={6}    className='mt-4 xs_col'>
                    <div className="box_summary">
                        <div className="title_summary">
                            <span>{t('num_employee')}</span>
                        </div>
                        <div className="img_summary">
                            <img src={img.businessman} alt="app_area" />
                        </div>
                        <div className="footer__summary flex flex-column">
                            <span className='num'> {data?.factory} </span>
                            <span className='text'>{t('text_employee')}  </span>
                        </div>
                    </div>
                </Col>
                <Col xl={2} lg={2} md={4} sm={6}    className='mt-4 xs_col'>
                    <div className="box_summary">
                        <div className="title_summary">
                            <span>{t('num_products')}</span>
                        </div>
                        <div className="img_summary">
                            <img src={img.manufacture} alt="app_area" />
                        </div>
                        <div className="footer__summary flex flex-column">
                            <span className='num'> {data?.products} </span>
                            <span className='text'>{t('text_products')}    </span>
                        </div>
                    </div>
                </Col>
                <Col xl={2} lg={2} md={4} sm={6}    className='mt-4 xs_col'>
                    <div className="box_summary">
                        <div className="title_summary">
                            <span>{t('num_sales')}</span>
                        </div>
                        <div className="img_summary">
                            <img src={img.sales} alt="app_area" />
                        </div>
                        <div className="footer__summary flex flex-column">
                            <span className='num'> {data?.sales} </span>
                            <span className='text'>{t('text_sales')}</span>
                        </div>
                    </div>
                </Col> 
            </Row>
        </div>
    )
}

export default Summary