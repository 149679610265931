import React from 'react'
import './aboutHome.scss'
import img from '../../../constants/Img'
import { Col, Row } from 'react-bootstrap'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'


const AboutHome = ({ data, t }) => {

    return (
        <div className='overflow-hidden app__about_home'>
            <Row >
                <Col xl={3} lg={3} md={3} sm={3}></Col>
                <Col xl={9} lg={9} md={9} sm={12} className='app__aboutus'>
                    <img src={img.AboutHome} alt="مصنع الوطن " className='w-100' />
                    <div className="content">
                        <div className="container app__about_title flex justify-content-center flex-column align-items-start w-100">
                            <div className="header">
                                <h3>{data?.section_title} </h3>
                            </div>
                            <p>{data?.section_content} </p>
                        </div>
                        <Link to="/about">
                            <Button label={t('btn_more')} />
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default AboutHome
