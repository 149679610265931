import axios from "axios";
//Home Services
export const SubProductsServices = async (lang, productsId) => {
    const url = `https://api.alwatanfac.com.sa/api/home/products?sub_category_id=${productsId}`;
    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': lang,
        }
    }).catch((err) => {
    })
    return data
};
export default SubProductsServices
