import React from 'react'
import './aboutHome.scss'
import { Col, Container, Row } from 'react-bootstrap'
import img from '../../../constants/Img'
const Quality = ({ data, t }) => {
    return (
        <div className='app_quality'>
            <Row className='flex align-items-center'>
                <Col xl={6} lg={6} md={6} sm={12} className='h-100'>
                    <Container>
                        <div className="content">
                            <h2>{t("text_quality")}</h2>
                            <ul>
                                {
                                    data?.map((item, index) => (
                                        <li key={index}>{item?.title}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </Container>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className='flex justify-content-end'>
                    <img src={img.Quality} alt="" className='w-100' />
                </Col>
            </Row>
        </div>
    )
}

export default Quality
