
import AOS from 'aos'
import { motion } from 'framer-motion'
import React, { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Icons from '../../constants/Icons'
import img from '../../constants/Img'
import { FetchDataContext } from '../../context/FetchData'
import { LocalizationContext } from '../../context/LangChange'
import './footer.scss'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
    let { t } = useTranslation()
    let { settings } = useContext(FetchDataContext)

    let { data, setProductsId } = useContext(FetchDataContext)
    useEffect(() => {
        AOS.init({
            once: true,
        });
        AOS.refresh();
    }, [])
    let navigation = useNavigate()
    const handleChange = (id) => {
        setProductsId(id)
        navigation('/products')
    };
    return (
        <div className="footer-top">

            <div className='app__Footer'>
                <div className="overlayer"></div>
                <Container>
                    <Row >
                        <Col xl={4} lg={4} md={6} sm={12} className=' content1 mt-3      '
                        >
                            <div >
                                <img src={img.LogoLight} width={"200px"} className='mb-4' alt="logo right click" />
                                <br />

                                <span className='app__footer-p '>{settings?.description} </span>
                                <hr className='line__footer' />
                                <div className="social">
                                    {/* <span>follow</span> */}
                                    <div className="social__icons">
                                        {/*       <Component.SocialMedia
                                                FacebookData={settings?.facebook}
                                                twitterData={settings?.twitter}
                                                instagramData={settings?.instagram}
                                                youtubeData={settings?.youtube}
                                                linkedinData={settings?.linked_in}
                                                SvgColor={'#FFFFFF'}
                                                styles={'group_scoial-media-notPosotions'}
                                            /> */}
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xl={2} lg={2} md={6} sm={12} className='  content2 mt-3' >
                            <div
                            >
                                <h1>   {t("footer_quick_links")}  </h1>

                                <div className="footer-ui">
                                    <ul>
                                        <Link to={'/'}> <li>{t("nav_home")}</li></Link>
                                        <Link to={'/about'}> <li>{t("nav_about")}</li></Link>
                                        <Link to={'/products'}> <li>{t("nav_product")}</li></Link>
                                        <Link to={'/client'}> <li>{t("nav_service")}</li></Link>
                                        <Link to={'/services'}> <li>{t("nav_client")}</li></Link>
                                        <Link to={'/contact'}> <li>{t("nav_contact")}</li></Link>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xl={2} lg={2} md={6} sm={12} className='  content3 mt-3' >
                            <div

                            >
                                <h1>   {t("footer_products")}  </h1>

                                <div className="footer-ui">
                                    <ul>
                                        <Link to={'/products'}> <li>{t("product_all")}</li></Link>

                                        {
                                            data?.categories?.map((item, index) => (
                                                <Link to={'/products'} onClick={() => handleChange(item?.id)}
                                                    key={index}> <li>{item?.name}</li></Link>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className=' content4 mt-3' >
                            <div  >
                                <h1>   {t("footer_contact")}  </h1>

                                <div className="app__footer-contact-us">
                                    <a className='content-Contact-Us'
                                        href="https://www.google.com/maps/place/24%C2%B044'20.8%22N+46%C2%B038'48.4%22E/@24.7390256,46.6458355,18.85z/data=!4m4!3m3!8m2!3d24.739117!4d46.646789?entry=ttu"
                                        target="_blank" rel="noreferrer">
                                        <motion.div
                                            className="phoneIcons round d-flex justify-content-center align-items-center "
                                            whileHover={{ rotate: 360 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Icons.location />
                                        </motion.div>
                                        <p className="m-0 p-0">
                                            {settings?.address}
                                        </p>
                                    </a>
                                    <a href={`tel:${settings?.phone}`} target='_blank' rel="noreferrer" className='m-0 p-0 content-Contact-Us'>
                                        <motion.div
                                            className="phoneIcons round d-flex justify-content-center align-items-center "
                                            whileHover={{ rotate: 360 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Icons.CallOutlite />
                                        </motion.div>
                                        <span dir='ltr'>{' + '}{settings?.phone?.replace(/\D/g, '')?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}</span>
                                    </a>
                                    <a href={`https://wa.me/${settings?.whats}`} target='_blank' rel="noreferrer" className='m-0 p-0  content-Contact-Us'>
                                        <motion.div
                                            className="phoneIcons round d-flex justify-content-center align-items-center "
                                            whileHover={{ rotate: 360 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Icons.Whatsapp />
                                        </motion.div>
                                        <span dir='ltr'>{settings?.whats?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}</span>
                                    </a>
                                    <a href={`mailto: ${settings?.email}`} target='_blank' rel="noreferrer" className='m-0 p-0  content-Contact-Us'>
                                        <motion.div
                                            className="phoneIcons round d-flex justify-content-center align-items-center "
                                            whileHover={{ rotate: 360 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Icons.Email />
                                        </motion.div>
                                        <span dir='ltr'>{settings?.email}</span>
                                    </a>

                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>

                <div className="footer__copyright  ">
                    <p className='text-light'>  &copy; 2024  <a href="https://rightclick.sa/" target='_blank' className='text-light text-decoration-underline'>right click</a> </p>
                </div>


            </div>


            <div className="top  " onClick={() => window.scroll(0, 0)} >
                <Icons.ArrowTop />
            </div>
        </div>
    )
}
