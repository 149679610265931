import React from 'react'
import img from '../../../constants/Img'
import './ServicesHome.scss'
import Cookies from 'js-cookie'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import Icons from '../../../constants/Icons'
const ServicesHome = ({ data, t }) => {
    return (
        <div className='app__services'>

            <div className="container app__services_title flex justify-content-center flex-column align-items-start w-100">
                <div className="header">
                    <h3>{data?.section_title} </h3>
                </div>
                <p>{data?.section_content}</p>
            </div>
            <div className="view_Lg">

                <div className="app__services_content">
                    <img src={Cookies.get('i18next') === "en" ? img.Services2 : img.ServicesAr} className='w-100 app__services_img' alt="" srcset="" />
                    {
                        data?.features[0] &&
                        <div className="services1">
                            <h1>{data?.features[0]?.title}</h1>
                            <p>{data?.features[0]?.description}</p>
                        </div>
                    }
                    {
                        data?.features[1] &&
                        <div className="services2">
                            <h1>{data?.features[1]?.title}</h1>
                            <p>{data?.features[1]?.description}</p>
                        </div>
                    }
                    {
                        data?.features[2] &&
                        <div className="services3">
                            <h1>{data?.features[2]?.title}</h1>
                            <p>{data?.features[2]?.description}</p>
                        </div>
                    }

                </div>
            </div>
             <div className="view_sm"> 
                <VerticalTimeline layout={`${Cookies.get('i18next') === "en" ? '1-column-left' : '1-column-right'}`} >
                    {
                        data?.features?.map((item, index) => (
                            <VerticalTimelineElement
                                key={index}
                                className="vertical-timeline-element--work"
                                contentStyle={{ color: '#24282C', background: '#fff' }}
                                // contentArrowStyle={{ borderRight: '7px solid  #24282C' }}
                                // date="2011 - present"
                                // iconStyle={{ color: '#fff', background: '#fff' }}
                                lineClassName="vertical-timeline-line-black"
                                icon={<img src={img.about_dot}/>}
                            >
                                <h3 className="vertical-timeline-element-title">{item?.title}</h3>
                                <p>{item?.description}</p>
                            </VerticalTimelineElement>
                        ))
                    }
                </VerticalTimeline>
            </div>  
        </div>
    )
}

export default ServicesHome 