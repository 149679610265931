import axios from "axios";
//Home Services
export const ProductsServices = async (lang, productsId, pageId) => {
    const url = `https://api.alwatanfac.com.sa/api/home/products?category_id=${productsId}&page=${pageId}`;

    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': lang,
        }
    }).catch((err) => {
    })
    return data
};
export default ProductsServices
