import cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { LocalizationContext } from '../../../context/LangChange';
import './Slider.scss';

const Slider = ({ images ,t}) => {

    const [activeIndex, setActiveIndex] = useState(0)
    let { isLang } = useContext(LocalizationContext);

    const itemTemplate = (item) => {
        return (
            <div className='image__container'>
                <img src={item?.image} alt={item.alt} className='image__slider' />
                <div className={`${cookies.get('i18next') === "en" ? 'content__silder  ' : 'content__silder content__silder-rtl'}  `}>
                    <div className={`content ${cookies.get('i18next') === "en" ? 'contentEn' : 'contentAr'} `}>
                        <span >  {item?.title} </span>
                        <p className={`${cookies.get('i18next') === "en" ? 'text-left  ' : 'text-right'}  `}>{item?.description}</p>
                        <Link className='link__home_btn_sidebar' to={'/contact'}>
                            <Button label={t('btn_slider')} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    };

    const next = () => {
        setActiveIndex(prevState => (prevState === images?.length - 1) ? 0 : prevState + 1)
    }

    const prev = () => {
        setActiveIndex((prevState) => (prevState === 0 ? 0 : prevState - 1));
    };
    return (
        <div style={{ width: '100%' }} className='app__slider'>
            <Galleria value={images}
                showThumbnails={false}
                className="image-slider-indicators"
                showIndicatorsOnItem={true}
                activeIndex={activeIndex}
                onItemChange={(e) => setActiveIndex(e.index)}
                circular
                autoPlay
                transitionInterval={2000}
                showIndicators item={itemTemplate}
            /> 
            <div className="btn_sliders_left" dir='ltr'>
                <Button icon="pi pi-angle-left" onClick={prev} />
                <Button icon="pi pi-angle-right" onClick={next} />
            </div>
        </div>


    );
}

export default Slider;
