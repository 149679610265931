import { Carousel } from 'primereact/carousel';
import React from 'react';
import { Link } from 'react-router-dom';
import './clients.scss';
import { Button } from 'primereact/button';

const ClientsHome = ({ products, t }) => {

    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    const productTemplate = (item) => {

        return (
            <div className="  surface-border  border-round m-2 text-center py-5 px-3">
                <div className="mb-3  ">
                    <img src={`${item.image}`} alt={'product'} className="w-7 p-2 shadow-2 h-10rem object-fit-contain" />
                </div>
            </div>
        );
    };
    return (
        <>
            <div className="app__clients">
                <h1 className='text-center'>{products?.section_title}</h1>
                <div className="pt- pb-5  " dir='ltr'>

                    <Carousel
                        value={products?.clients}
                        numVisible={4}
                        numScroll={1}
                        responsiveOptions={responsiveOptions}
        
                        circular
                        autoplayInterval={3000}
                        itemTemplate={productTemplate} />

                </div>
                <div className="mx-auto btn_view mb-5 w-10 flex justify-content-center align-items-center">
                    <Link className='link__home_btn_sidebar' to={'/client'}>
                        <Button label={t('btn_View')} />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ClientsHome
