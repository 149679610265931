import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Component from '../constants/Component';
import { FetchDataContext } from '../context/FetchData';
import '../style/App.scss'
import img from '../constants/Img';
const Alwatan = () => {
  const location = useLocation();
  let { loader } = useContext(FetchDataContext)


  const [navBarBg, setnavBarBg] = useState('navbar-nav')

  useEffect(() => {
    if (location.pathname !== '/') {
      setnavBarBg('navbar-nav');
    } else {
      setnavBarBg('navbar-navOnthorPAge')
    }
  }, [location.pathname])
  return (
    <>
      <div className="loading__page">
        {
          loader ?
            <>
              <Component.NavBar navbarColor={navBarBg} />
              <div className='m_navbar_body' style={{ position: 'relative' }}  >
                <Outlet></Outlet>
              </div>

              <Component.Footer />
            </> :
            <div className='loading_content'>
              <img src={img.Logo} alt="" />
            </div>
        }
      </div>
    </>
  )
}

export default Alwatan
