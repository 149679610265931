import axios from "axios";
//Home Services
export const ProductsDetailsServices = async (lang,id) => {
    const url = `https://api.alwatanfac.com.sa/api/home/products/${id}`;
    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': lang,
        }
    }).catch((err) => {
    })
    return data
};
export default ProductsDetailsServices  