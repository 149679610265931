import { Button } from 'primereact/button'
import React from 'react'
import img from '../../../constants/Img'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Offers = ({ data, t }) => {
    return (
        <div className='app__offers mb-5'>
            <Row className='row_md'>
                {
                    data?.map((item, index) => (
                        <Col key={index} xl={6} lg={6} md={12} sm={12} className='mt-4 col_md'>
                            <div className="app__offer_items shadow">
                                <div className="content">
                                    <span>{item?.title}  </span>
                                    <h3>{item?.description}  </h3>
                                    <Link to={'/contact'}>
                                        <Button label={t("btn_slider")} size='small' />
                                    </Link>
                                </div>
                                <div className="img">
                                    <img src={item?.image} alt="" className='w-100' />
                                </div>
                            </div>
                        </Col>
                    ))
                }

            </Row>
        </div>
    )
}

export default Offers
