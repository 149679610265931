import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Component from '../../../constants/Component';
import { FetchDataContext } from '../../../context/FetchData';
import './products.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // your primary color
        },
        secondary: {
            main: '#fff', // your new secondary color
        },
    },
});
const ProductsHome = () => {
    let { data, setCategoriesId, subCategories, products, setProductsId, setSubProductsId, setSubCategories } = useContext(FetchDataContext)
    const { t } = useTranslation()

    const [value, setValue] = useState(0);

    // Visual Identity
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCategoriesId(newValue)
        setProductsId(newValue)
    };
    const handleChangeSubCategories = (event, newValue) => {
        setValue(newValue);
        setSubProductsId(newValue)
    };

    useEffect(() => {
        setProductsId(0)
        setSubCategories([])
    }, [])


    return (
        <>
            <div className="app__products">
                <div className="app__products_title flex justify-content-center flex-column align-items-center w-100">
                    <div className="header">
                        <h3> {t("our_Products")}  </h3>
                    </div>
                </div>

                <>
                    <ThemeProvider theme={theme}>
                        <Box sx={{ width: '100%', margin: 'auto', marginTop: '50px', display: 'flex', justifyContent: 'center', padding: '5px', borderRadius: '15px', background: '#fff' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="inherit"
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                indicatorColor="secondary"
                                // centered={true}
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab style={{ textTransform: 'capitalize', justifyContent: 'center' }} value={0} label={t('product_all')} variant={value === 'visual_production' ? 'contained' : 'standard'} sx={{ justifyContent: 'center' }} />
                                {
                                    data?.categories?.map((item, index) => (
                                        <Tab key={index} style={{ textTransform: 'capitalize', justifyContent: 'center' }} value={item.id} label={item.name} variant={value === 'visual_production' ? 'contained' : 'standard'} sx={{ justifyContent: 'center' }} />
                                    ))
                                }
                            </Tabs>
                        </Box>
                        {
                            subCategories?.length >= 1 &&
                            <Box sx={{ width: '80%', margin: 'auto', marginTop: '0px', display: 'flex', justifyContent: 'center', padding: '5px', borderRadius: '10px', border: '1px solid #707070', background: '#fff' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChangeSubCategories}
                                    textColor="inherit"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    indicatorColor="secondary"
                                    // centered={true}
                                    aria-label="scrollable auto tabs example"


                                >
                                    {
                                        subCategories?.map((item, index) => (
                                            <Tab key={index} style={{ textTransform: 'capitalize', justifyContent: 'center' }} value={item.id} label={item.name} variant={value === 'visual_production' ? 'contained' : 'standard'} sx={{ justifyContent: 'center' }} />
                                        ))
                                    }
                                </Tabs>
                            </Box>
                        }

                        <Box sx={{ p: 2, overflow: 'hidden' }}>
                            <Component.Items page='projects' data={products} />
                        </Box>

                    </ThemeProvider>
                </>
                <div className="mx-auto mt-4 btn_view mb-5 w-10 flex justify-content-center align-items-center">

                    <Link className='link__home_btn_sidebar' to={'/products'}>
                        <Button label={t('btn_View')} />
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ProductsHome;
