 import Home from '../Page/Home/Home.jsx';
import NavBar from './../Components/NavBar/NavBar';
import Alwatan from './../Layout/Alwatan';
import Summary from './../Page/Home/Summary/Summary';
import Slider from './../Page/Home/Slider/Slider';
import ProductsHome from './../Page/Home/Products/ProductsHome';
import Offers from './../Page/Home/Products/Offers';
import ServicesHome from './../Page/Home/Services/ServicesHome';
import AboutHome from './../Page/Home/About/AboutHome';
import Quality from './../Page/Home/About/Quality';
import ClientsHome from './../Page/Home/Clients/ClientsHome';
import SubscribeHome from './../Page/Home/subscribe/SubscribeHome';
import { Footer } from './../Components/Footer/Footer';
import About from './../Page/About/About';
import Clients from './../Page/Clients/Clients';
import Contact from './../Page/Contact/Contact';
import Products from './../Page/Products/Products';
import Services from './../Page/Services/Services';
import Items from './../Page/Products/Items';
import ProductDetails from './../Page/Products/ProductDetails';


const Component = {
  // Layout
  Alwatan,
  NavBar,
  Footer,
  // Home components
  Home,
  Summary,
  Slider,
  ProductsHome,
  Offers,
  AboutHome,
  ServicesHome,
  Quality,
  ClientsHome,
  SubscribeHome,
  //**********************************
  About,
  Clients,
  Contact,
  Products,
  Services,
  Items,
  ProductDetails
}

export default Component


