import React, { useContext, useEffect, useState } from 'react'
import { LocalizationContext } from '../../context/LangChange';
import ProductsDetailsServices from '../../utils/ProductsDetailsServices';
import Cookies from 'js-cookie';
import { Link, useParams } from 'react-router-dom';
import img from '../../constants/Img';
import { Container } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

const ProductDetails = () => {
  const { t } = useTranslation()

  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext)
  const [Index, setIndex] = useState(1);
  const [images, setImages] = useState([])
  const [productsDetails, setProductsDetails] = useState([]);
  const fetchProducts = async () => {
    ProductsDetailsServices(Cookies.get('i18next'), id).then(({ data }) => {
      if (data?.status === 200) {
        setProductsDetails(data?.data);
        setImages(data?.data?.images);
      }
    }).catch((error) => {
    });
  }
  useEffect(() => {
    window.scroll(0, 0) 
    document.title = t('product_title')

    const timeOut = setTimeout(() => {
      fetchProducts()
    }, 200);
    return () => clearTimeout(timeOut);
  }, [id, isLang]);
  return (
    <div>
      <div className="app__Product_details">
        <div className="slider_hover">
          <Container>
            <div className='card__image card__image_lg mb-5'>
              {
                images &&
                <>
                  <div className="small-images-container flex flex-column justify-content-center align-items-center">
                    <h4 className='Prudact_name text-center w-100'>( {productsDetails?.name} )</h4>
                    {images?.slice(0, 3).map((item, i) => (
                      item && item ? (
                        <img
                          className={i === Index ? 'small-image selected-image w-100' : 'small-image'}
                          key={i}
                          src={item}
                          onMouseEnter={() => setIndex(i)}
                          style={{ objectFit: 'contain', objectPosition: '50% 50%' }}
                          alt='image'
                        />
                      ) : null
                    ))}
                  </div>
                  <div className="product-detail-image p-5 "   >
                    {images?.length === 1 ?
                      <img
                        src={images[0]}
                        alt='product detail' 
                        style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}

                      /> :
                      <img
                        src={images && images[Index]}
                        alt='product detail'
                        style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}
                      />
                    }
                  </div>
                  <div className="small-btn-container bg-black-alpha-90">
                    <Link to={'/contact'}>
                      <Button label={t('btn_slider')} />
                    </Link>
                  </div>  
                </>
              }

            </div>
            <div className="card__image_sm">
              <div className='card__image_sm   mb-5'>
                {
                  images &&
                  <>

                    <div className="product-detail-image "   >
                      {images?.length === 1 ?
                        <img
                          src={images[0]}
                          alt='product detail'
                          style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}
                        /> :
                        <img
                          src={images && images[Index]}
                          alt='product detail'
                          style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}
                        />
                      }
                    </div>
                    <div className="small-images-container  flex flex-row justify-content-center align-items-center">
                      {/* <h4 className='Prudact_name text-center w-100'>( {productsDetails?.name} )</h4> */}
                      {images?.slice(0, 3).map((item, i) => (
                        item && item ? (
                          <img
                            className={i === Index ? 'small-image selected-image w-100' : 'small-image'}
                            key={i}
                            src={item}
                            onMouseEnter={() => setIndex(i)}
                            style={{ objectFit: 'contain', objectPosition: '50% 50%' }}
                            alt='image'
                          />
                        ) : null
                      ))}
                    </div>
                    <div className="small-btn-container">
                      <Link to={'/contact'}>
                        <Button label={t('btn_slider')} />
                      </Link>
                    </div>
                  </>
                }

              </div>
            </div>
          </Container>
            <div className="card__content pt-4 pb-4">
            <Container>
              <div className=" header flex justify-content-between align-items-center" >
                <h2>{t('about_product')}</h2>
                <span>( {productsDetails?.name} )</span>
              </div>
              <div className="body">
                <h3>{t('description_product')}</h3>
                <p>{productsDetails?.description}</p>
              </div>
              <div className="size">
                <span className="size_header">{t('size_product')}</span>
                <div className="content flex ">
                  <div className="height flex flex-column gap-3">
                    <span className='hight_num'>{productsDetails?.height} {t('sizeM_product')}</span>
                    <span className='hight_text'> {t('height_product')}</span>
                  </div>
                  <div className=" width flex flex-row  gap-5 align-items-end  ">
                    <div className="width flex flex-column gap-3">
                      <span className='width_num'>{productsDetails?.width} {t('sizeM_product')}</span>
                      <span className='width_text  '> {t('width_product')}   </span>
                    </div>
                    <span className='width_after mb-2' ></span>
                  </div>
                </div>
              </div>
              <div className="color">
                <span className="size_header" >{t('color_product')}</span>
                <br />
                <div className="flex_color">

                  <div className="f-color" style={{ background: productsDetails?.colors && productsDetails.colors[0]?.color }}>
                  </div>
                  <div className="s-color" style={{ background: productsDetails?.colors && productsDetails.colors[1]?.color }}>
                  </div>
                </div>
              </div>
            </Container>
          </div> 
        </div>
      </div>
    </div>
  )
}

export default ProductDetails