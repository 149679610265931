import Cookies from 'js-cookie';
import React, { createContext, useEffect, useState } from 'react';
export const LocalizationContext = createContext([])

function LangChange({ children }) {

  const [isLang, setIsLang] = useState(Cookies.get('i18next'));

  useEffect(() => {
    Cookies.set('i18next', isLang)
  }, [isLang])

  return (
    <LocalizationContext.Provider value={{ isLang, setIsLang }}>
      {children}
    </LocalizationContext.Provider>
  )
}

export default LangChange