import { ReactComponent as Facebook } from '../assets/SVG/facebook.svg';
import { ReactComponent as Email } from '../assets/SVG/email.svg';
import { ReactComponent as Instagram } from '../assets/SVG/instagram.svg';
import { ReactComponent as Language } from '../assets/SVG/language.svg';
import { ReactComponent as Linkedin } from '../assets/SVG/linkedin.svg';
import { ReactComponent as Phone } from '../assets/SVG/phone.svg';
import { ReactComponent as Twitter } from '../assets/SVG/twitter.svg';
import { ReactComponent as ArrowRight } from '../assets/SVG/arrow-right.svg';
import { ReactComponent as ArrowTop } from '../assets/SVG/arrow-top.svg';
import { ReactComponent as Whatsapp } from '../assets/SVG/whatsapp.svg';
import { ReactComponent as location } from '../assets/SVG/location.svg';
import { ReactComponent as CallOutlite } from '../assets/SVG/call.svg';

const Icons = {
    Facebook,
    Email,
    Instagram,
    Language,
    Linkedin,
    Phone,
    Twitter,
    ArrowRight,
    ArrowTop,
    Whatsapp,
    location,
    CallOutlite
}

export default Icons;
