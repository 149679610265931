import React, { useContext, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Component from '../../constants/Component'
import { FetchDataContext } from '../../context/FetchData'

const Home = () => {
  const { t } = useTranslation()
  let { data } = useContext(FetchDataContext)
  useEffect(() => {
    window.scroll(0, 0)
    document.title = t('app_title')
  }, [t])

  return (
    <>
      <Component.Slider images={data?.sliders} t={t} />
      <Component.AboutHome data={data?.about_us} t={t} />
      <Container>
        <Component.Summary data={data?.about_us} t={t} />
        <Component.ProductsHome t={t} />
      </Container>
      <Component.Offers  data={data?.offers} t={t} />
      <Component.ServicesHome data={data?.features} t={t} />
      <Component.Quality data={data?.quality} t={t} />
      <Container>
        <Component.ClientsHome products={data?.clients} t={t} />
      </Container>
      {/* <Component.SubscribeHome t={t} />  */}
    </>
  )
}

export default Home